<template>
  <Button
      variant="buttonRedOutlined"
      :loading="loading"
      @clicked="$emit('clicked')"
      :text="trans('BUTTON:Skip')"
  />
</template>

<script>
import Button from "@/utils/crud/components/Button";

export default {
  name: "ButtonSkip",
  components: {Button},
  props:{
    loading: Boolean,
  }
}
</script>