<template>
  <div class="w-full text-s text-center">
    <span v-show="message !== null">
      {{ message }}
    </span>
    <span v-show="count !== null">
      {{ count }}
    </span>
  </div>
</template>

<script>
export default {
  name: "ProfilesCounter",
  props: {
    count: Number,
    message: String,
  }
}
</script>